import { DesktopSliderProps } from "@components/organisms/DesktopSlider/types"
import NavArrow from "public/images/components/carousel/arrow.svg"
import React, { memo } from "react"
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from "swiper/react"

import { Carousel } from "../../atoms/Carousel/Carousel"
import { SwiperStyles } from "../../atoms/Carousel/styles"
import { MemoizedProductCard } from "../ProductCard/ProductCard"
import * as S from "./styles"

const DesktopSlider: React.FC<DesktopSliderProps> = ({
  productIds,
  arrowId,
  listId,
  title,
  trackable,
  eventName,
}) => {
  const swiperNavClassPrevious = `productByGoal-slider-${arrowId}-prev`
  const swiperNavClassNext = `productByGoal-slider-${arrowId}-next`
  return (
    <SwiperStyles>
      <Carousel prevRef={swiperNavClassPrevious} nextRef={swiperNavClassNext}>
        {productIds.map((productId, index) => (
          <SwiperSlide key={productId.productid}>
            <MemoizedProductCard
              productId={productId.productid}
              dividerWidth="60%"
              listId={listId}
              listTitle={title}
              isComboProduct={productId.iscomboProduct}
              trackable={trackable}
              eventName={eventName}
              index={index}
            />
          </SwiperSlide>
        ))}
      </Carousel>
      <S.CarouselControlLeft className={swiperNavClassPrevious}>
        <NavArrow />
      </S.CarouselControlLeft>
      <S.CarouselControlRight className={swiperNavClassNext}>
        <NavArrow />
      </S.CarouselControlRight>
    </SwiperStyles>
  )
}

export const MemoizedDesktopSlider = memo(DesktopSlider)
