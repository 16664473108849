import styled from "styled-components"

export const CarouselControlLeft = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
  svg {
    transform: rotate(180deg);
  }
`
CarouselControlLeft.displayName = "S.CarouselControlLeft"

export const CarouselControlRight = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
  cursor: pointer;
`
CarouselControlRight.displayName = "S.CarouselControlRight"
